.cp-footer{
  padding: 40px 0 97px;
  text-align: center;
  background: #1f1f1f;
  .footer-logo{
    margin-bottom: 28px;
    img{
      width: 162px;
    }
  }
  .footer-content{
    .footer-line{
      &.footer-line_1{
        display: flex;
        align-content: center;
        justify-content: center;
        margin-bottom: 10px;
        .link{
          display: inline-flex;
          align-items: center;
          height:  20px;
          margin-right: 10px;
          &::after{
            top: auto;
            right: -6px;
          }
        }
      }
      &.footer-line_2{
        display: flex;
        align-content: center;
        justify-content: center;
        .link{
          display: inline-flex;
          align-items: center;
          height:  20px;
          margin-right: 10px;
          &::after{
            top: auto;
            right: -6px;
          }
        }
      }
      .link{
        position: relative;
        color: #B3B3B3;
        font-size: 12px;
        // line-height: 17px;
        &.link_last{
          margin-right: 0;
          &::after{
            display: none;
          }
        }
        &::after{
          position: absolute;
          top: 0;
          width: 1px;
          height: 12px;
          background-color: #B3B3B3;
          content: '';
        }
      }
    }
  }
}
