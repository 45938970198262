.cp-downlosdGuide{
  position: relative;
  height: 140px;
  .downlosdGuide-box{
    position:fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 140px;
    transform: translateY(40px);
    visibility: hidden;
    opacity: 0;
    transition: all 300ms ease-in-out;
    &.isShow{
      transform: translateY(0);
      visibility: visible;
      opacity: 1;
    }
  }
  .downlosdGuide-content{
    position: relative;
    z-index: 1;
    height: 100%;
    margin:0 auto;
    text-align: center;
    .down-guide{
      height: 100%;
    }
    .downlosdGuide-logo{
      width: 116px;
      height: 79px;
      margin-top: 32px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .downlosdGuide-list{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 10px;
      .item{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100px;
        height: 118px;
        margin-left: 15px;
        padding: 10px;
        background-color: #fff;
        border-radius: 4px;
        .item-code{
          flex-shrink: 0;
          width: 74px;
          height: 74px;
        }
        .item-title{
          color: #333;
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }
  .downlosdGuide-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: auto 140px;
    opacity: .7;
  }
}
