.swiper_big{
  position: relative;
  margin-top: 17px;
  margin-bottom: 56px;
  background-color: #fff;
  border-radius: 8px;
  .swiper-item{
    box-sizing: border-box;
    height: 370px;
    overflow: hidden;
    font-size: 0;
    border-radius: 0 0 8px 8px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .swiper_tab{
    text-align: center;
    :global{
      .cp-tabs{
        display: inline-block;
      }
    }
  }
  .swiper_btn{
    position: absolute;
    top: 50%;
    width: 49px;
    height: 49px;
    outline: none;
    transform: translateY(-25px);
    cursor: pointer;
    img{
      width: 100%;
      height: 100%;
    }
    &.pre_btn{
      background-image: url('//img10.360buyimg.com/paipai/jfs/t1/183479/7/18941/1222/6116432dE7bbdeb75/47ce9a5b8b97574b.png');
      background-repeat: no-repeat;
      background-size: cover;
      &:hover{
        background-image: url('//img10.360buyimg.com/paipai/jfs/t1/195814/24/18632/2923/611a1e15E41c58f80/18aeaec254b9e1f0.png');
      }
    }
    &.next_btn{
      background-image: url('//img10.360buyimg.com/paipai/jfs/t1/203444/40/1181/1273/611642feE5bf8d9a4/ed2a9e767d7fc1a8.png');
      background-repeat: no-repeat;
      background-size: cover;
      &:hover{
        background-image: url('//img10.360buyimg.com/paipai/jfs/t1/191755/35/18605/2930/611a09d3E6a21c3ad/f49003fe4338cb7a.png');
      }
    }
    &:first-of-type{
      left: -68px;
    }
    &:last-of-type{
      right: -68px;
    }
  }
  .swiper_index{
    background-repeat: no-repeat;
    background-position: 62px 30px;
    background-size: auto 102px;
    &.swiper_index_1{
      background-image: url('//img10.360buyimg.com/paipai/jfs/t1/200233/38/2928/7156/61164f81E28c42112/4754fc6341e13230.png');
    }
    &.swiper_index_2{
      background-image: url('//img10.360buyimg.com/paipai/jfs/t1/183354/1/19269/9067/61164395E489d785a/0400d43823e25ed4.png');
    }
    &.swiper_index_3{
      background-image: url('//img10.360buyimg.com/paipai/jfs/t1/202956/16/1186/9197/611643a2E75314e43/0792effcfb121ae9.png');
    }
    &.swiper_index_4{
      background-image: url('//img10.360buyimg.com/paipai/jfs/t1/183600/23/19186/8989/611643aeE33a1e5ed/160db9eb8473ebf9.png');
    }
    &.swiper_index_5{
      background-image: url('//img10.360buyimg.com/paipai/jfs/t1/198263/19/2989/9577/611643baEb27a5808/b153b0c1078317cb.png');
    }
    &.swiper_index_6{
      background-image: url('//img10.360buyimg.com/paipai/jfs/t1/188095/25/18244/9319/61164fd5Eed6f6693/6f98c5d41de549fc.png');
    }
  }
}
