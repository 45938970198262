.category{
  display: flex;
  align-items: center;
  justify-content: space-between;
  // margin-top: 34px;
  .category_item{
    position: relative;
    width: 308px;
    height: 183px;
    border-radius: 8px;
  }
  .item_content{
    position: absolute;
    bottom: 12px;
    left: 18px;
    z-index: 1;
    .title{
      margin-bottom: 12px;
      font-weight: 400;
      font-size: 22px;
      line-height: 30px;
    }
    .text{
      position: relative;
      margin-bottom: 4px;
      padding-left: 8px;
      color: rgba(255,255,255,1);
      font-size: 14px;
      line-height: 20px;
      opacity: 0.7;
      &:last-of-type{
        margin-bottom: 0;
      }
      &::before{
        position: absolute;
        top: 50%;
        left: 0;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        transform: translateY(-50%);
        content: '';
      }
    }

  }
  .img{
    position: absolute;
    right: 0;
    bottom: 0;
    width: auto;
    height: 183px;
  }
}
