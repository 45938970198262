.cp-tabs{
  position: relative;
  .tabs-content{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .tabs-item{
      position: relative;
      z-index: 2;
      padding: 26px 50px;
      color: #1f1f1f;
      font-size: 16px;
      font-family: SourceHanSansSC-Regular;
      line-height: 25px;
      outline: none;
      cursor: pointer;
      &::after{
        position: absolute;
        top: 50%;
        right: -.5px;
        width: 1px;
        height: 18px;
        background-color: rgba(217,217,217,1);
        transform: translateY(-50%);
        content: ' ';
      }
      &:last-of-type{
        &::after{
          display: none;
        }
      }
      &.active{
        color: #000;
        font-weight: bold;
      }
    }
  }
  .tabs-subline{
    position: absolute;
    bottom: 24px;
    z-index: 1;
    width: 104px;
    height: 16px;
    background: linear-gradient(270deg, rgba(254, 232, 44, 0.00) 0%, #FEE82C 100%);
    transition: left ease-in-out 450ms;
  }
}
