.inspect_title{
  padding-top: 56px;
  text-align: center;
  .ahsYanxuan{
    position: relative;
    color: #1F1F1F;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    &::before, &::after{
      position: absolute;
      top: 40%;
      width: 25px;
      height: 10px;
      background-image: url('~@/static/imgs/similar-icon.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      content: ' ';
    }
    &::before{
      left: -40px;
      transform: scaleX(-1);
    }
    &::after{
      right: -40px;
    }
  }
  .title-img{
    width: 178px;
    height: 100px;
  }
  .title-sub{
    margin-top: 8px;
    .sub-text{
      position: relative;
      display: inline-block;
      color: #1F1F1F;
      font-size: 16px;
      line-height: 24px;
    }
  }
  span{
    vertical-align: bottom;
  }
  .num{
    margin: 0 10px;
    font-weight: bold;
    font-size: 28px;
    line-height: 28px;
  }
  .brand_update{
    position: relative;
    margin-top: 6px;
    height: 148px;
    font-size: 0;
    img{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }
  .brand_bg{
    position: absolute;
    left: 0;
    bottom: 0;
    height: 127px;
    width: 100%;
    border-radius: 13px;
    background: #FFF;
  }
}
