/* 样式重置 */
html, body, ol, ul, li, h1, p{
  margin: 0;
  padding: 0;
}
ol, ul {
  list-style: none;
}
a, img, button, input, textarea, i, span, div {
  -webkit-tap-highlight-color:rgba(255, 255, 255, 0);
}
input, select, textarea {
  padding: 0;
  border-style: none
}
a {
  color: inherit;
  text-decoration: none;
}
body {
  box-sizing: border-box;
  color: #333;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  background-color: #F2F4F7;
  & *, & *::before, & *::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }
}

/* 清除浮动 */
.clearfix {
  *zoom: 1;
  &::after {
    display: table;
    clear: both;
    line-height: 0;
    content: "";
  }
}

.wrap{
  min-width: 1280px;
  overflow-x: hidden;
}
.content{
  width: 1280px;
  margin: 0 auto;
}




// .flex_cen_cen {
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
.flex_cen_bet {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex_cen_start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.flex_top_bet {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
// .flex_top_cen {
//   display: flex;
//   align-items: flex-start;
//   justify-content: center;
// }
// .flex_cen_end {
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
// }

.flex_top_start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

// .flex_wrap {
//   flex-wrap: wrap;
// }
