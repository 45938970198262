.huishou{
  padding-top: 56px;
  .title{
    margin-bottom: 26px;
    text-align: center;
    .highRecover{
      position: relative;
      color: #1F1F1F;
      font-weight: bold;
      font-size: 32px;
      &::before, &::after{
        position: absolute;
        top: 40%;
        width: 25px;
        height: 10px;
        background-image: url('~@/static/imgs/similar-icon.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        content: ' ';
      }
      &::before{
        left: -40px;
        transform: scaleX(-1);
      }
      &::after{
        right: -40px;
      }
    }
    .title-img{
      width: 110px;
      height: 80px;
      margin-bottom: 5px;
    }
    .title-sub{
      .sub-text{
        position: relative;
        color: #1f1f1f;
        font-size: 16px;
      }
    }
  }
  .bg{
    width: 100%;
    height: 400px;
  }
}
