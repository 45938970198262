.dbd{
  margin-top: 56px;
  padding: 40px 0 106px;
  background-color: #fff;
  .title{
    margin-bottom: 8px;
    text-align: center;
    .highRecover{
      position: relative;
      color: #1F1F1F;
      font-weight: bold;
      font-size: 32px;
      &::before, &::after{
        position: absolute;
        top: 40%;
        width: 25px;
        height: 10px;
        background-image: url('~@/static/imgs/similar-icon.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        content: ' ';
      }
      &::before{
        left: -40px;
        transform: scaleX(-1);
      }
      &::after{
        right: -40px;
      }
    }
    .title-sub{
      .sub-text{
        position: relative;
        color: #1f1f1f;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
  .bg_box{
    position: relative;
    margin-top: 40px;
    .bg{
      width: 216px;
      height: 213px;
      margin-right: 50px;
      &:last-child{
        margin-right: 0;
      }
    }
    &::before,&::after{
      position: absolute;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      content: '';
    }
  }
}
