.comments{
  .comments_title{
    color: #1f1f1f;
    font-weight: bold;
    font-size: 28px;
    text-align: center;
  }
  .swiper{
    position: relative;
    margin-top: 10px;
    &::before,&::after{
      position: absolute;
      width: 106px;
      height: 84px;
      background-image: url('//img10.360buyimg.com/paipai/jfs/t1/192028/25/17886/1411/61164242E034ef77c/a56b121186189d87.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      content: ' ';
    }
    &::before{
      top: 0;
      left: -94px;
      transform: rotate(180deg);
    }
    &::after{
      top: 0;
      right: -94px;
    }
  }
  .comments_group{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 132px;
  }
  .comments_item{
    box-sizing: border-box;
    width: 413px;
    height: 132px;
    padding: 18px 24px;
    background-color: #fff;
    border-radius: 8px;
    visibility: visible;
    .left{
      flex-shrink: 0;
      margin-right: 20px;
      text-align: center;
      .avator{
        width: 42px;
        height: 42px;
      }
      .name{
        margin: 7px 0;
        color: #1f1f1f;
        font-weight: 500;
        font-size: 14px;
      }
      .isPlus{
        width: 62px;
        height: 20px;
        background-image: url('//img10.360buyimg.com/paipai/jfs/t1/184769/4/19341/3679/611a0bf2Ec68b92d5/0dff33b46b433dfb.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
    .brand{
      margin-top: 2px;
      color: #1f1f1f;
      font-weight: 600;
      font-size: 16px;
      span{
        position: relative;
        margin-right: 16px;
        &::after{
          position: absolute;
          top: 50%;
          right: -5px;
          width: 1px;
          height: 9px;
          margin-right: -2px;
          background-color:  #1f1f1f;
          transform: translateY(-50%);
          content: '';
        }
        &:last-of-type{
          margin-right: 0;
          &::after{
            display: none;
          }
        }
      }
    }
    .bottom{
      display: -webkit-box;
      height: 57px;
      margin-top: 16px;
      overflow: hidden;
      color: #707070;
      font-size: 14px;
      text-overflow: ellipsis;
      word-break: break-all;
      visibility: visible;

      /*! autoprefixer: ignore next */
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;

    }
  }
}
