
.header{
  position: relative;
  .banner{
    width: 100%;
    overflow: hidden;
    text-align: center;
    background-color: #BBEC01;
    .annouce{
      position: relative;
      top: 4px;
      width: 100%
    }
  }
  .top{
    .logo{
      display: block;
      padding: 12px 0;
      text-align: center;
      background: rgba(255,255,255,1);
      img{
        width: 79px;
        height: 78px;
      }
    }
    .nav{
      height: 48px;
      color: #fff;
      line-height: 48px;
      text-align: center;
      background-color: #1F1F1F;
      .nav-item{
        display: inline-block;
        height: 48px;
        margin-right: 124px;
        padding: 0 30px;
        font-size: 16px;
        line-height: 48px;
        outline: none;
        cursor: pointer;
        &.nav-item_link{      
          margin-right: 0;
          color: rgba(255,255,255,1);
          font-weight: bold;
          background: #424242;   
        }
      }
      .arrowRight{
        width: 10px;
        height: 11px;
      }
    }
  }
}

